import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Tervetuloa!</h1>
    <p>
      Sahaamme järeää havu- ja lehtipuutukkia kohteisiin, joiden on määrä kestää
      yli sukupolvien.
    </p>
    <p>
      Meiltä sahauspalvelu ja sahatavara, jollaista et rautakaupasta saa - silti
      rautakaupan hinnoilla.
    </p>
    <p>
      Tiedustele sahausaikatauluja ja saatavissa olevaa sahatavaraa. P. 040 5743
      898
    </p>
    <p>Osoite: Takajärventie 436; 13500; Hämeenlinna</p>
    <StaticImage
      src="../images/halla_laudat.jpg"
      width={1200}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      /*       style={{ marginBottom: `1.45rem` }} */
    />
    <StaticImage
      src="../images/honkametsa.jpg"
      width={1200}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      /*       style={{ marginBottom: `1.45rem` }} */
    />
    <StaticImage
      src="../images/normal_honkametsa2.jpg"
      width={1200}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      /*       style={{ marginBottom: `1.45rem` }} */
    />
    <p>
      <Link to="/halla-oy/">
        Alkuperäinen Hallan Saha Kotkan Hallansaaressa
      </Link>{" "}
      <br />
    </p>
  </Layout>
)

export default IndexPage
